// COLOURS
//
$black: #1D1B1A !default
$white: #FFFFFF !default
$yolk: #FFC700 !default
$grey-100: #F8F8F8 !default //#FBFAF8 !default
$grey-300: #DDE2E8 !default
$grey-800: #232838 !default
$peach-200: #FAEBDF !default
$peach-500: #F7D2B5 !default
$orange: #FF3D02 !default
// 
$border-color: rgba($black, 0.12)
