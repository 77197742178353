@import 'styles/site.sass'

.Main
  color: currentColor
  max-width: $text-max-width
  // border-top: 1px solid currentColor
  //background: white
  //border-radius: 40px
  //padding: 40px
  // padding-top: $space-s
  
  p
    @extend %body-m
    margin-bottom: units(2)
    
  h5
    @extend %heading-m
    
  &:not(:last-child)
    h5
      // margin-bottom: $space-m
      // @include min($screen-l)
      //   margin-bottom: $space-l
      
    .block
      //display: block
    
  .block
    height: units(1)
    background: $peach-200
    width: $block-width
    display: none
