//
$screen-s: 400px
$screen-m: 700px
$screen-l: 1080px
$screen-xl: 1400px
//
//GLOBAL Z-INDEXES - groups into 1000s
$z-navigation: 1000
$z-contextual-navigation: 3000
$z-main-menu: 1100
$z-pickers: 5000
// AT THE TOP
$z-overlay: 10000
//
// -------- //
// CUSTOMISATION
// -------- //
//
$radius: size(12)
$radius-s: size(2)
//
$secondary-tab-width: size(312)
$tab-active-border-width: size(2)
$border-light: 1px solid rgba($black, 0.3)
$border-lightest: 1px solid $border-color
$border-action: $tab-active-border-width solid $grey-300
$overlay-bg: rgba($black, 0.2)
//
$elevation-1: 0px 40px 50px -40px rgba($black, 0.08)//0px 1px 4px rgba($black, 0.1), 0px 8px 24px rgba($black, 0.12)
$elevation-2: 0px 1px 4px rgba($black, 0.1)
//
$border-xs: size(4)
$border-s: units(3)
$border-m: units(5)
$border-l: units(7)
//
$space-xs: units(3)
$space-s: units(5)
$space-sm: units(8)
$space-m: units(10)
$space-l: units(15)
$space-xl: units(25)
//
$block-width: units(32)
$border-width: size(4)
//
$top-nav-height: $space-m
$nav-bar-height: units(10)
$nav-bar-active-height: units(7)
$text-max-width: units(85)
$accordion-max-width: units(110)
//
