@import 'styles/site.sass'

.Main
  padding-bottom: $space-s
  width: 100%
  margin: 0
  
  .image
    width: 100%
    border-radius: $radius
    overflow: hidden
    
  @include min($screen-m)
    padding-bottom: $space-m
    display: flex
    flex-direction: row-reverse
    
    .image
      width: 80%
      max-width: units(130)
  
  &.full
    width: 100%
    max-width: unset
    
  .caption
    @extend %body-xs
    color: rgba($black, 0.7)
    padding-top: units(2)
    // font-style: italic
    
    @include min($screen-m)
      padding: units(2) units(3) 0 0
      width: 20%
      //text-align: right
