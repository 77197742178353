@import 'styles/fonts'
@import 'styles/site'
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap')

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

*, *:before, *:after
  -webkit-box-sizing: border-box
  -moz-box-sizing: border-box
  box-sizing: border-box

html
  font-size: 62.5%
  //overflow-x: hidden

li
  list-style-type: none

body
  padding: 0
  margin: 0
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Helvetica Neue', sans-serif
  -moz-osx-font-smoothing: grayscale
  position: relative
  width: 100%
  -webkit-tap-highlight-color: rgba(0,0,0,0)
  -webkit-font-smoothing: antialiased
  font-size: 100%
  color: $black
  //overflow-x: hidden

  &.fixed
    position: fixed
    top: 0
    left: 0
    overflow: hidden
    height: 100vh

h1, h2, h3, h4, h5, h6, li, ul, html, body
  margin: 0
  padding: 0
  border: 0
  font-weight: normal

li
  color: inherit

p
  margin: 0
  color: inherit

  a
    text-decoration: underline

strong
  letter-spacing: inherit

blockquote
  margin: 0

a
  text-decoration: none
  color: inherit

h1, h2, h3, h4, h5, h6 p
  a
    color: inherit
