@import 'styles/site.sass'

.Main

.content
  @extend %round-section
  overflow: hidden
  // display: grid
  // grid-template-columns: calc(#{$left-column-width} - #{$space-m}) 1fr //$right-column-width
  // grid-column-gap: $space-m


.main
  @extend %round-section
  position: relative
  // padding-top: $space-m
  // max-width: $text-max-width
.feedback
