@import 'styles/site.sass'

.Main
  @extend %body-s
  @extend %medium
  position: relative
  color: $black

  &.stuck
    span
      height: $nav-bar-active-height
      color: $black
    .bar
      background-color: $black !important

  &:hover
    .bar
      display: block !important
    .block
      opacity: 1
    span
      opacity: 1

  &.active
    .bar
      display: block
    span
      opacity: 1

  .content
    pointer-events: none
    padding: 0 units(6)

  span
    position: relative
    display: flex
    height: $nav-bar-height
    justify-content: center
    align-items: center
    opacity: 0.9
    @include animate(0.1s)
  
  .bar
    position: absolute
    bottom: 0
    left: 0
    right: 0
    height: $tab-active-border-width
    background: $black
    display: none
