@import 'styles/site.sass'

.Main
  cursor: pointer
  position: relative
  @include animate(0.1s)
  flex: 1
  display: flex
  flex-direction: column
  align-items: space-between
  justify-content: space-between
  color: currentColor
  border-bottom: $border-width solid transparent  
  padding-bottom: units(3)
  // background: $white
  // border-radius: 20px
  padding: size(30) 0
  &:not(:first-child)
    border-top: 1px solid rgba(0,0,0, 0.2)
  

  @include min($screen-l)
    padding: 0 size(50)

    &:not(:first-child)
      border-top: none
      border-left: 1px solid rgba(0,0,0, 0.2)

    .cta
      margin-top: units(6)
  
  header
    display: block !important

    h5
      @extend %body-s
      @extend %bold
      margin-bottom: units(2)
      display: none
    
    p
      @extend %body-s
  
  .cta
    @extend %body-s
    @extend %bold
    margin-top: units(2)

  &:hover

    h5
      text-decoration: underline
    .cta
      background-position: right units(3) center
      text-decoration: underline
