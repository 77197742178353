@import 'styles/site.sass'

.Main
  display: grid
  grid-gap: size(4)
  grid-template-columns: 1fr 1fr 1fr
  @include min($screen-m)
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr
  @include min($screen-l)
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr
    grid-gap: size(4)
  
  .image
    width: 100%
    background: white;
    border-radius: $border-m
