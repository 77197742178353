@font-face
  font-family: 'FacebookOptimistic'
  src: local('MyFont'), url('https://about.fb.com/wp-content/themes/fbcorp/fonts/optimistic/Optimistic_Display_W_Md.woff2') format('woff')

// @font-face
//   font-family: 'FacebookReader-Regular'
//   src: local('MyFont'), url('https://about.fb.com/wp-content/themes/fbcorp/fonts/reader/regular/FacebookReader-Regular.woff2') format('woff')

// @font-face
//   font-family: 'FacebookReader-Medium'
//   src: local('MyFont'), url('https://about.fb.com/wp-content/themes/fbcorp/fonts/reader/medium/FacebookReader-Medium.woff2') format('woff')

  ///import css-variables from
@font-face 
  font-family: Facebook Sans
  font-weight: 400
  src: url(https://sustainability.fb.com/wp-content/themes/fb-sustainability/assets/fonts/facebooksans_rg-webfont.woff2) format("woff2"),url(https://sustainability.fb.com/wp-content/themes/fb-sustainability/assets/fonts/facebooksans_rg-webfont.woff) format("woff")


@font-face 
  font-family: Facebook Sans
  font-style: italic
  font-weight: 400
  src: url(https://sustainability.fb.com/wp-content/themes/fb-sustainability/assets/fonts/facebooksans_it-webfont.woff2) format("woff2"),url(https://sustainability.fb.com/wp-content/themes/fb-sustainability/assets/fonts/facebooksans_it-webfont.woff) format("woff")


@font-face 
  font-family: Facebook Sans
  font-weight: 100
  src: url(https://sustainability.fb.com/wp-content/themes/fb-sustainability/assets/fonts/facebooksans_hair-webfont.woff2) format("woff2"),url(https://sustainability.fb.com/wp-content/themes/fb-sustainability/assets/fonts/facebooksans_hair-webfont.woff) format("woff")


@font-face 
  font-family: Facebook Sans
  font-style: italic
  font-weight: 100
  src: url(https://sustainability.fb.com/wp-content/themes/fb-sustainability/assets/fonts/facebooksans_hairit-webfont.woff2) format("woff2"),url(https://sustainability.fb.com/wp-content/themes/fb-sustainability/assets/fonts/facebooksans_hairit-webfont.woff) format("woff")


@font-face 
  font-family: Facebook Sans
  font-weight: 300
  src: url(https://sustainability.fb.com/wp-content/themes/fb-sustainability/assets/fonts/facebooksans_lt-webfont.woff2) format("woff2"),url(https://sustainability.fb.com/wp-content/themes/fb-sustainability/assets/fonts/facebooksans_lt-webfont.woff) format("woff")


@font-face 
  font-family: Facebook Sans
  font-style: italic
  font-weight: 300
  src: url(https://sustainability.fb.com/wp-content/themes/fb-sustainability/assets/fonts/facebooksans_ltit-webfont.woff2) format("woff2"),url(https://sustainability.fb.com/wp-content/themes/fb-sustainability/assets/fonts/facebooksans_ltit-webfont.woff) format("woff")


@font-face 
  font-family: Facebook Sans
  font-weight: 700
  src: url(https://sustainability.fb.com/wp-content/themes/fb-sustainability/assets/fonts/facebooksans_bd-webfont.woff2) format("woff2"),url(https://sustainability.fb.com/wp-content/themes/fb-sustainability/assets/fonts/facebooksans_bd-webfont.woff) format("woff")


@font-face 
  font-family: Facebook Sans
  font-style: italic
  font-weight: 700
  src: url(https://sustainability.fb.com/wp-content/themes/fb-sustainability/assets/fonts/facebooksans_bdit-webfont.woff2) format("woff2"),url(https://sustainability.fb.com/wp-content/themes/fb-sustainability/assets/fonts/facebooksans_bdit-webfont.woff) format("woff")


@font-face 
  font-family: Facebook Sans
  font-weight: 900
  src: url(https://sustainability.fb.com/wp-content/themes/fb-sustainability/assets/fonts/facebooksans_he-webfont.woff2) format("woff2"),url(https://sustainability.fb.com/wp-content/themes/fb-sustainability/assets/fonts/facebooksans_he-webfont.woff) format("woff")


@font-face 
  font-family: Facebook Sans
  font-style: italic
  font-weight: 900
  src: url(https://sustainability.fb.com/wp-content/themes/fb-sustainability/assets/fonts/facebooksans_heit-webfont.woff2) format("woff2"),url(https://sustainability.fb.com/wp-content/themes/fb-sustainability/assets/fonts/facebooksans_heit-webfont.woff) format("woff")
