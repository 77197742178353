@import 'styles/site.sass'

.Main
  // @extend %round-section
  background: $white
  color: $black
  padding: units(8) 0
  border-top: 1px solid rgba(black, 0.2)

  @include min($screen-m)
    padding: units(15) 0

  h2
    @extend %heading-l
    margin-bottom: $space-m
  
  .content
    display: grid
    grid-gap: $space-l 
    @include min($screen-m)
      grid-template-columns: 1fr 1fr
      // justify-content: flex-end
      // align-items: flex-end
