@mixin min($width)
  @media screen and (min-width: $width)
    @content

@mixin max($width)
  @media screen and (max-width: $width)
    @content

@mixin between($min, $max)
  @media screen and (min-width: $min) and (max-width: $max)
    @content

@mixin vertical-align
  position: absolute
  top: 50%
  transform: translateY(-50%)

@mixin center-both($xoffset: -50%, $yofset: -50%)
  position: absolute
  top: 50%
  left: 50%
  transform: translate($xoffset, $yofset)

@mixin gradient($colA: #D00387, $colB: #600C8C, $angle: 100deg)
  background-color: mix($colA, $colB)
  background: linear-gradient($angle, $colA 0%, $colB 100%)

@mixin animate($time: 0.7s)
  transition: all $time ease-in-out

@function get-random-color()
  $red: random(255)
  $green: random(255)
  $blue: random(255)
  @return rgba($red, $green, $blue, 1)


@mixin aspect-ratio($ratio-or-width, $height: null)
  $padding: percentage(1/$ratio-or-width)
  @if $height
    $padding: percentage($height/$ratio-or-width)

  width: 100%
  background-size: cover
  background-position: center center
  background-repeat: no-repeat

  &:before
    content: ""
    float: left
    padding-bottom: $padding

  &:after
    content: ""
    display: table
    clear: both

%clearfix
  &:after
    content: ""
    display: table
    clear: both
