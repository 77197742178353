@import 'styles/site.sass'

.Main
  width: $border-m
  height: $border-m
  border-radius: 0 0 0 size(12)
  @include min($screen-l)
    width: $border-l
    height: $border-l
    
  display: flex
  align-items: center
  justify-content: center
  position: fixed
  top: 0
  right: 0
  background: $white
  cursor: pointer
  @include animate(0.3s)

  &:after
    content: ""
    width: units(2)
    height: units(2)
    border-radius: 500px
    background: $black
    @include animate(0.3s)
    
  &:hover
    //background: darken($black, 10%)
    &:after
      width: units(1)
      height: units(1)
